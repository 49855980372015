import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public advanced: boolean = false;
  public browse: boolean = false;
  public countriesSelected = [];
  public cropsSelected = [];
  public pestsSelected = [];
  public languagesSelected = [];
  
  public advancedSearchBy = "criteria";
  public name = "";

  public result;
  public advancedFilter;

  public filter = null;

  public reportTool;
  
  constructor(private api: ApiService, private router: Router, private gtm: GtmService) { }

  ngOnInit(): void {
    if (this.router.url === '/browse') {
      this.gtm.push({
        event: 'page',
        pageName: '/browse'
      });
      this.browse = true;
      this.search(this.filter);
    } else if (this.router.url === '/advanced') {
      this.gtm.push({
        event: 'page',
        pageName: '/advanced'
      });
      this.advanced = true;
    }
    window.scroll({ 
      top: 0, 
      left: 0
    });
  }

  search(filter: any) {
    if (this.advanced) {
      if (this.advancedSearchBy === 'name') {
        this.gtm.push({
          event: 'search',
          item_category: 'advanced',
          item_name: 'name-or-id'
        });
        this.api.getTools(this.name, null, null, null, null, null, filter).subscribe((result) => {
          this.result = result;
        });
      } else {
        this.gtm.push({
          event: 'search',
          item_category: 'advanced',
          item_name: 'criteria'
        });
        this.api.getTools(null, null, null, null, null, this.advancedFilter, filter).subscribe((result) => {
          this.result = result;

          for (let rootKey of Object.keys(result.filter)) {
            for (let sectionKey of Object.keys(result.filter[rootKey].sections)) {
              for (let subsectionKey of Object.keys(result.filter[rootKey].sections[sectionKey].subsections)) {
                for (let itemKey of Object.keys(result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items)) {
                  result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].checked = 
                    this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].checked;
                  if (result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].checked) {
                    result.filter[rootKey].sections[sectionKey].open = true;
                  }
                  if (!result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num) {
                    result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num = 0;
                  }
                  if (result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items) {
                    for (let subitemKey of Object.keys(result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items)) {
                      result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].checked = 
                        this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].checked;
                        if (result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].checked) {
                          result.filter[rootKey].sections[sectionKey].open = true;
                        }
                      if (!result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num) {
                        result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num = 0;
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
    } else {
      let forComparison = [];
      if (this.result && this.result.results) {
        forComparison = this.result.results.filter(r => r.selected).map(r => r.tool_id);
      }

      if (this.browse) {
        this.gtm.push({
          event: 'search',
          item_category: 'quick',
          item_name: 'browse'
        });
        this.api.getTools(null, null, this.cropsSelected, null, null, null, filter).subscribe((result) => {
          this.result = result;
          for (let tool of this.result.results) {
            if (forComparison.includes(tool.tool_id)) {
              tool.selected = true;
            }
          }
        });
      } else {
        this.gtm.push({
          event: 'search',
          item_category: 'quick',
          item_name: 'criteria'
        });
        this.api.getTools(null, this.countriesSelected, this.cropsSelected, this.pestsSelected, this.languagesSelected, null, filter).subscribe((result) => {
          this.result = result;
          for (let tool of this.result.results) {
            if (forComparison.includes(tool.tool_id)) {
              tool.selected = true;
            }
          }
        });
      }
    }
  }

  setAdvanced(advanced) {
    this.advanced = advanced;
    this.reset();
  }

  setAdvancedSearchBy(value) {
    this.advancedSearchBy = value;
  }

  filterChange(filter) {
    this.filter = filter;
    this.search(filter);
  }

  reset() {
    this.countriesSelected = [];
    this.cropsSelected = [];
    this.pestsSelected = [];
    this.languagesSelected = [];
    
    this.advancedSearchBy = "criteria";
    this.advancedFilter = undefined;
    this.name = "";
    this.result = undefined;
  }

  advancedSearch(filter) {
    if (filter) {
      this.advancedFilter = filter;
      this.filter = filter;

      this.search(this.filter);
    } else {
      this.search(this.filter);
    }
  }

  add() {
    this.router.navigate(['submit']);    
  }
}
