<div class="wrapper">
<div class="container">
  <ul class="partners">
    <li class="IPM"><a href="https://www.ipmdecisions.net/"></a></li>
    <li class="MPS"><a href="https://mps.si/en/"></a></li>
    <li class="IJS"><a href="https://www.ijs.si"></a></li>
  </ul>
</div>
</div>
<div class="wrapper">
<div class="container">
  <ul class="nav">
    <li routerLink="/">Quick search</li>
    <li routerLink="/advanced">Advanced search</li>
    <li routerLink="/browse">Browse the catalogue</li>
    <li routerLink="/submit">Add new DSS</li>
  </ul>
</div>
</div>
<div class="wrapper">
<div class="container">
  <ul class="links">
    <li>&copy; Copyright</li>
    <li><a href="/assets/IPMAdviser_Privacy_Policy.pdf">Privacy policy</a></li>
    <li><a href="/assets/IPMAdviser_Terms_Conditions.pdf">Terms & Conditions</a></li>
  </ul>
</div>
</div>

<span class="discreet">IPM Adviser V1.0. last updated {{lastSyncDate | date: 'dd.MM.yyyy'}}</span>
<span class="discreet right">UI Design by Peter Novak</span>