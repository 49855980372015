import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private api: ApiService) { }

  public lastSyncDate: any;

  ngOnInit(): void {
    this.api.getSync().subscribe((sync:any) => {
      this.lastSyncDate = sync.performedAt;
    });
  }

}
