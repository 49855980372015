import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { GtmService } from 'src/app/gtm.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @Input() tool;
  @Output() done = new EventEmitter();

  public submitted: boolean = false;
  public loading = false;

  public comment = "";
  public firstname = "";
  public lastname = "";
  public email = "";
  public role;
  public error;

  public roleItems;
  public errorFields = [];

  constructor(private api: ApiService, private router: Router, private gtm: GtmService) {
    this.roleItems = this.api.roleItems;
  }

  ngOnInit(): void {
    this.gtm.push({
      event: 'page',
      pageName: '/report',
      item_name: this.tool.name
    });
    window.scroll({ 
      top: 0, 
      left: 0
    });
  }

  missingMandatoryFields() {
    let missing = false;

    let mandatoryFields = ['comment', 'firstname', 'lastname', 'email', 'role'];

    for (let field of mandatoryFields) {
      if (this[field] === undefined || this[field] === null || this[field] === "") {
        this.errorFields.push(field);
        missing = true;
      }
    }
    return missing;
  }

  submit() {
    this.error = undefined;
    this.errorFields = [];

    if (this.missingMandatoryFields()) {
      this.error = "Please complete all mandatory fields";
      return;
    }

    if (!this.api.isValidEmail(this.email)) {
      this.error = "Invalid Email address";
      this.errorFields.push('email');
      return;
    }
    
    this.gtm.push({
      event: 'report-submit',
      item_name: this.tool.name
    });

    this.loading = true;
    this.api.submitReport(this.tool.tool_id, this.firstname, this.lastname, this.email, this.role2txt(this.role), this.comment).subscribe(() => {
      this.submitted = true;
      this.loading = false;
    }, (e) => {
      this.submitted = false;
      this.loading = false;
      this.error = "An error occurred. Please try again later.";
    });
  }

  role2txt(roleId) {
    let role = this.roleItems.find(r => r.id===roleId);

    if (role) {
      return role.name;
    } else {
      return "";
    }
  }

  close() {
    this.done.emit();
  }
}
