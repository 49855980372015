<div>
  <div class="advanced-container" *ngIf="input">
    <ul class="subselector">
      <li (click)="setAdvancedSearchBy('criteria')" [ngClass]="{'active': advancedSearchBy==='criteria'}">Search by criteria</li>
      <li (click)="setAdvancedSearchBy('name')" [ngClass]="{'active': advancedSearchBy!=='criteria'}">Search by name or id</li>
    </ul>
    <div class="name-search" *ngIf="advancedSearchBy==='name'">
      <div class="input">
        <span class="heading">DSS Name or Id</span>
        <input type="text" [(ngModel)]="name" (keyup.enter)="search()" (ngModelChange)="onNameChange()" placeholder="Enter name of searched DSS or its Id visible on IPM Decisions platform." />
      </div>
      <div class="search">
        <span (click)="search()">Search</span>
      </div>
    </div>
    <div *ngIf="advancedSearchBy==='criteria'">
      <div class="inner" *ngIf="items">
        <app-progress [nodes]="items" [(current)]="currentIdx"></app-progress>
        <div *ngIf="currentIdx === 0">
          <h3 class="problem">What <em>problem</em> are you solving?</h3> <div class="clearChoices" *ngIf="filterNum('Problem') > 0" (click)="clearFilters('Problem')">CLEAR CHOICES ({{filterNum('Problem')}})</div>

          <app-node-input [items]="items[0]" (change)="update()" [cols]="true"></app-node-input>
        </div>
        <div *ngIf="currentIdx === 1">
          <h3 class="implementation">What <em>implementation</em> would you like to use?</h3> <div class="clearChoices" *ngIf="filterNum('Analysis') > 0" (click)="clearFilters('Analysis')">CLEAR CHOICES ({{filterNum('Analysis')}})</div>

          <app-node-input [items]="items[1]" (change)="update()" [cols]="false"></app-node-input>
        </div>
        <div *ngIf="currentIdx === 2">
          <h3 class="output">What <em>output</em> do you prefer?</h3> <div class="clearChoices" *ngIf="filterNum('Outcomes') > 0" (click)="clearFilters('Outcomes')">CLEAR CHOICES ({{filterNum('Outcomes')}})</div>

          <app-node-input [items]="items[2]" (change)="update()" [cols]="false"></app-node-input>
        </div>
      </div>
      <div class="lower">
        <div class="left">
          <a *ngIf="currentIdx>0" class="back" href="" (click)="prev($event)">Back</a>
          <a *ngIf="currentIdx==0" class="back disabled" href="">Back</a>
        </div>
        <div class="right">
          <a *ngIf="currentIdx<2" class="next" href="" (click)="next($event)">Next step</a>
          <a *ngIf="currentIdx==2" class="next" href="" (click)="next($event)">Search</a>
        </div>
      </div>
    </div>
  </div>

  <div class="advanced-container" *ngIf="!input && advancedSearchBy==='name'">
    <div class="phase2" *ngIf="advancedSearchBy==='name'">
      <div class="name-search-result">
        <div class="input">
          <span class="heading">DSS Name or Id</span>
          <input type="text" [(ngModel)]="name" (keyup.enter)="search()" (ngModelChange)="onNameChange()" />
        </div>
        <div class="search">
          <span (click)="search()">Search</span>
        </div>
      </div>
    </div>
    <!--
    <div class="phase2 criteria-search" *ngIf="advancedSearchBy==='criteria'">
      <ul>
        <li>
          <h2 class="problem">
            <span>Problem <em *ngIf="filterNum('Problem')>0">{{filterNum('Problem')}} <div class="link" (click)="clearFilters('Problem')">x</div></em></span>
            <ul *ngIf="filterNum('Problem')>0"><li *ngFor="let item of listFilters('Problem')">{{item.title}}<div class="remove" (click)="removeItem('Problem', item)"></div></li></ul>
          </h2>
        </li>
        <li>
          <h2 class="analysis">
            <span>Analysis <em *ngIf="filterNum('Analysis')>0">{{filterNum('Analysis')}} <div class="link" (click)="clearFilters('Analysis')">x</div></em></span>
            <ul *ngIf="filterNum('Analysis')>0"><li *ngFor="let item of listFilters('Analysis')">{{item.title}}<div class="remove" (click)="removeItem('Analysis', item)"></div></li></ul>
          </h2>
        <li>
          <h2 class="outcomes">
            <span>Outcomes <em *ngIf="filterNum('Outcomes')>0">{{filterNum('Outcomes')}} <div class="link" (click)="clearFilters('Outcomes')">x</div></em></span>
            <ul *ngIf="filterNum('Outcomes')>0"><li *ngFor="let item of listFilters('Outcomes')">{{item.title}}<div class="remove" (click)="removeItem('Outcomes', item)"></div></li></ul>
          </h2>
      </ul>
      <div class="search">
        <span (click)="search()">Search</span>
      </div>
      <div class="close" (click)="clear()"></div>
    </div>
    -->
  </div>
</div>