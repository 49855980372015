import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nonempty'
})
export class NonemptyPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return value;
    } else {
      return " ";
    }
  }
}
