<div class="logo"><img *ngIf="tool.logo" [src]="'/api/images/logo/' + tool.logo" /></div>
<div class="content">
  <h2>{{tool.name}}</h2>
  <div class="body">
    <ul class="left">
      <li><em>Country</em><span *ngIf="tool.development_country">{{tool.development_country.name + (tool.development_country_assumed ? '*' : '')}}</span></li>
      <li><em>Region</em><span><span *ngFor="let gf of tool.geographic_focus; let i=index">{{(i==0) ? '' : ', '}}{{gf}}</span></span></li>
    </ul>
    <ul class="center">
      <li><em>Crop</em><app-item-list [items]="tool.crops"></app-item-list></li>
      <li><em>Pest</em><app-pest-disease-list [pest_diseases]="tool.pest_diseases"></app-pest-disease-list></li>
    </ul>
    <ul class="right">
      <li><em>Language</em><app-item-list [items]="tool.languages"></app-item-list></li>
      <li><em>Price</em><span>{{price2string(tool.price)}}</span></li>
      <li><em>Date</em><span>{{tool.added | date:'d.M.yyyy'}}</span></li>
    </ul>
  </div>
  <ul class="links">
    <li (click)="toggleMoreOpen()" class="button more" [ngClass]="{open: moreOpen}">DETAILS</li>
    <li class="button comparison" [ngClass]="{'selected': tool.selected}" (click)="toggleComparison()">COMPARISON</li>
    <li *ngIf="tool.links.length === 1" class="button website"><a [href]="tool.links[0].url | link">WEBSITE</a></li>
    <li *ngIf="tool.links.length > 1" (click)="openModal()" class="button website">WEBSITE
      <app-modal [show]="urlmodalShow" (showChange)="onModalClose($event)">
        <div class="links">
          <h1>Links</h1>
          <div *ngFor="let link of tool.links"><a [href]="link.url | link">{{link.name}}</a></div>
        </div>
      </app-modal>
    </li>    
    <li *ngIf="tool.ipm_dss_model_code" class="button onIPM" (click)="ipmmodalShow=true">Available on IPM Decisions Platform</li>
    <app-modal [show]="ipmmodalShow" width="746" (showChange)="onIPMModalClose($event)">
      <div class="split">
        <div class="left">
          <h1>Available on IPM Decisions Platform</h1>
          
          <span>The IPM Decisions Platform is a one-stop shop for decision support in integrated pest management (IPM). <br/><br/>It is a web-based framework that provides farmers, advisors, researchers and DSS developers with access to a wide range of decision support systems and weather data from across Europe through a single user interface. The platform allows you to:
            <ol>
              <li>use DSS,</li>
              <li>compare the results of the available DSS,</li>
              <li>adapt the parameters within the decision support systems to use them in a wider range of countries and</li>
              <li>allow users to develop novel DSS by combining different DSS or parts of DSS (models and tools).</li>
            </ol></span>
        
          <div class="button" (click)="openLink()">Visit IPM Decisions Platform</div>
        </div>
        <div class="right">
          <h2>Additional info about <b>{{tool.name}}</b><em>(provided by IPM Decisions Platform)</em></h2>
          <div class="logo"><img *ngIf="tool.logo" [src]="'/api/images/logo/' + tool.logo" /></div>
          <span [innerHTML]="tool.description | formatDSSDescription"></span>
        </div>
        <div class="close" (click)="ipmmodalShow=false"></div>
      </div>
    </app-modal>
  </ul>
  <div *ngIf="moreOpen" class="more">
    <h2 class="basic">Basic information<ul class="links"><li class="button report" *ngIf="moreOpen" (click)="report()">UPDATE INFORMATION</li></ul></h2>
    <h3>Tool</h3>
    <ul>
      <li><em>Consecutive number</em> <span>{{tool.idx}}</span></li>
      <li><em>Name of DSS</em> <span>{{tool.name}}</span></li>
      <li><em>Link to web page</em> <span *ngFor="let link of tool.links; let idx=index">{{idx > 0 ? ',' : ''}}{{(link.name && link.name.length > 0) ? link.name : link.url}}</span></li>
      <li><em>Owner of DSS</em> <span>{{tool.owner}}</span></li>
      <li><em>Country of development</em> <span *ngIf="tool.development_country">{{tool.development_country.name + (tool.development_country_assumed ? '*' : '')}}</span></li>
      <li><em>Country - tested</em> <span>{{tool.tested_countries}}</span></li>
    </ul>
    
    <h4>Geographic focus</h4>
    <ul>
      <li><em>Northern Europe</em> <span>{{yesOrNo(tool.geographic_focus_neu)}}</span></li>
      <li><em>Central Europe</em> <span>{{yesOrNo(tool.geographic_focus_ceu)}}</span></li>
      <li class="noborder"><em>Southern Europe</em> <span>{{yesOrNo(tool.geographic_focus_seu)}}</span></li>
    </ul>

    <h2 class="challenge">Challenge</h2>
    <h3>Definition</h3>
    <ul>
      <li><em>Water pollution / plant protection</em><span>{{plantOrWater(tool.definition)}}</span></li>
    </ul>
    <h3>Constraints</h3>
    <ul>
      <li><em>Space</em><span>{{nationalInternational(tool.space_constraints)}}</span></li>
      <li class="noborder"><em>Time</em><span>{{shortLong(tool.time_constraints)}}</span></li>
    </ul>

    <h2 class="problem">Problem</h2>
    <h3>Plant protection</h3>
    <h4>Arable crops</h4>
    <ul>
      <li *ngFor="let crop of arableCrops"><em>{{crop}}</em><span>{{includes(tool.crops, crop)}}</span></li>
      <li><em></em><span></span></li>
      <li *ngFor="let crop of otherCrops"><em>{{crop}}</em><span>{{includes(tool.crops, crop)}}</span></li>
    </ul>
    <h4>Diseases</h4>
    <ul>
      <li *ngFor="let disease of diseases"><em>{{disease}}</em><span>{{includes(tool.pest_diseases, disease, 1)}}</span></li>
    </ul>
    <h4>Insects</h4>
    <ul>
      <li *ngFor="let insect of insects"><em>{{insect}}</em><span>{{includes(tool.pest_diseases, insect, 2)}}</span></li>
    </ul>
    <h3>Function</h3>
    <ul>
      <li><em>Monitoring</em><span>{{yesOrNo(tool.function_monitoring)}}</span></li>
      <li><em>Forecasting</em><span>{{yesOrNo(tool.function_forecasting)}}</span></li>
      <li class="noborder"><em>Planning</em><span>{{yesOrNo(tool.function_planning)}}</span></li>
    </ul>

    <h2 class="analysis">Analysis</h2>
    <h3>Implemenation</h3>
    <ul>
      <li><em>Access to DSS</em><span>{{onlineOffline(tool.access)}}</span></li>
    </ul>
    <h4>Language</h4>
    <ul>
      <li><em>Available languages</em><span *ngFor="let lang of tool.languages; let idx=index">{{idx > 0 ? ', ' : ''}}{{lang.name}}</span></li>
      <li><em>National</em><span>{{yesOrNo(tool.national_language)}}</span></li>
      <li><em>Available in English</em><span>{{yesOrNo(tool.available_in_english)}}</span></li>
    </ul>
    <ul>
      <li><em>Login required</em><span>{{yesOrNo(tool.login_required)}}</span></li>
      <li><em>Access to references</em><span>{{yesOrNo(tool.access_to_references)}}</span></li>
    </ul>
    <h4>Price</h4>
    <ul>
      <li><em>Free</em><span>{{freeNonfree(tool.price)}}</span></li>
    </ul>
    <h3>Data required</h3>
    <h4>Weather</h4>
    <ul>
      <li><em>Weather</em><span>{{yesOrNo(tool.weather)}}</span></li>
      <li><em>Obtaining weather data</em><span>{{autoManual(tool.weather_data)}}</span></li>
    </ul>
    <h4>Field data</h4>
    <ul>
      <li><em>Field observations</em><span>{{yesOrNo(tool.field_observations)}}</span></li>
      <li><em>Date of sowing</em><span>{{yesOrNo(tool.field_sowing_date)}}</span></li>
      <li><em>Pre crop</em><span>{{yesOrNo(tool.field_pre_crop)}}</span></li>
      <li><em>Crop variety</em><span>{{yesOrNo(tool.field_crop_variety)}}</span></li>
      <li class="noborder"><em>Other data</em><span>{{yesOrNo(tool.field_other)}}</span></li>
    </ul>

    <h2 class="outcomes">Outcomes</h2>
    <h3>Output</h3>
    <ul>
      <li><em>Map</em><span>{{yesOrNo(tool.output_map)}}</span></li>
      <li><em>Text</em><span>{{yesOrNo(tool.output_text)}}</span></li>
      <li><em>Numerical/categorical</em><span>{{yesOrNo(tool.output_numerical)}}</span></li>
    </ul>
    <h3>Target</h3>
    <ul>
      <li><em>Farmers</em><span>{{yesOrNo(tool.audience_farmers)}}</span></li>
      <li><em>Advisors</em><span>{{yesOrNo(tool.audience_advisors)}}</span></li>
      <li><em>Researchers</em><span>{{yesOrNo(tool.audience_researchers)}}</span></li>
    </ul>
    <ul>
      <li class="noborder"><em>Scale</em><span>{{smallMediumLarge(tool.scale)}}</span></li>
    </ul>
  </div>
</div>