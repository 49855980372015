import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  @Input() nodes;
  @Input() current: number;
  @Output() currentChange = new EventEmitter();

  public width = 140;
  public height = 72;
  public strokeWidth = 3;

  constructor() { }

  ngOnInit(): void {
  }

  select(idx) {
    this.currentChange.emit(idx);
  }
}
