import { Injectable } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { GoogleTagManagerService }  from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor(private ccService: NgcCookieConsentService, private gtmService: GoogleTagManagerService) { }

  push(tag: any) {
    if (this.ccService.hasConsented()) {
      this.gtmService.pushTag(tag);
    }
  }
}