import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit {
  private _tools;
  public alltools;
  
  @Input() public set tools(val) {
    this._tools = val;
    this.alltools = [...val];

    while(this.alltools.length < 4) {
      this.alltools.push({});
    }
  };
  public get tools() {
    return this._tools;
  }
  @Output() toolsChange = new EventEmitter();

  @Output() backClicked = new EventEmitter();

  @Input() arableCrops;
  @Input() otherCrops;
  @Input() diseases;
  @Input() insects;

  public markDifferences = false;
  public markSimilarities = false;

  constructor() { }

  ngOnInit(): void {
  }

  back() {
    this.toolsChange.emit(this._tools);
    this.backClicked.emit();
  }

  remove(tool) {
    tool.selected = false;
    this.tools = this.tools.filter(t => t.tool_id != tool.tool_id);
    if (this.tools.length === 0) {
      this.back();
    }
  }

  yesOrNo(val) {
    if (val === true) {
      return 'Yes';
     } else if(val === false) {
      return 'No';
    } else {
      return ' ';
     }
  }

  autoManual(val) {
    if (val === 'A') {
      return 'Automatic';
    } else if (val === 'M') {
      return 'Manual';
    } else if (val === 'N') {
      return 'No';
    }

    return ' ';
  }

  freeNonfree(val) {
    if (val === 'F') {
      return 'Free';
    } else if (val == 'N') {
      return 'Not free';
    } else if (val == 'L') {
      return 'Limited';
    }

    return ' ';
  }

  onlineOffline(val) {
    if (val === 'ONL') {
      return 'Online';
    } else if (val === 'OFL') {
      return 'Offline';
    }
    return ' ';
  }

  nationalInternational(val) {
    if (val === 'N') {
      return 'National';
    } else if (val === 'I') {
      return 'International';
    }
    return ' ';
  }

  shortLong(val) {
    if (val === 'S') {
      return 'Short-term';
    } else if (val ==='L') {
      return 'Long-term'
    } else if (val === 'B') {
      return 'Both;'
    }

    return ' ';
  }

  plantOrWater(val) {
    if (val === 'PP') {
      return 'Plant protection';
    } else if (val === 'WP') {
      return 'Water pollution';
    }
    return ' ';
  }

  smallMediumLarge(val) {
    if (val === 'S') {
      return 'Small';
    } else if (val === 'M') {
      return 'Medium';
    } else if (val === 'L') {
      return 'Large';
    }
    return ' ';
  }

  includes(collection, name, type = undefined) {
    let potential;
    if (type) {
      potential = collection.filter(i => i.name === name && i.type_id === type);
    } else {
      potential = collection.filter(i => i.name === name);
    }

    return potential.length === 0 ? 'No' : 'Yes';
  }

  toggleMarkDifferences() {
    this.markDifferences = !this.markDifferences;
    if (this.markDifferences && this.markSimilarities) {
      this.markSimilarities = false;
    }
  }

  toggleMarkSimilarities() {
    this.markSimilarities = !this.markSimilarities;
    if (this.markDifferences && this.markSimilarities) {
      this.markDifferences = false;
    }
  }

  getProp(tool, prop) {
    let parts = prop.split("|");

    if (parts.length == 1) {
      return tool[prop];
    } else {
      let first = parts.shift();
      if (tool[first]) {
        return this.getProp(tool[first], parts.join("|"));  
      } else {
        return undefined;
      }
    }
  }

  mark(prop) {
    if (this.markDifferences) {
      let val = this.getProp(this.tools[0], prop);
      for (let i=0; i<this.tools.length; i++) {
        if (this.tools[i].idx === undefined) {continue;}
        if (val !== this.getProp(this.tools[i], prop)) {
          return 'mark';
        }
      }
      return '';
    } else if (this.markSimilarities) {
      let val = this.getProp(this.tools[0], prop);
      for (let i=0; i<this.tools.length; i++) {
        if (this.tools[i].idx === undefined) {continue;}
        if (val !== this.getProp(this.tools[i], prop)) {
          return '';
        }
      }
      return 'mark';
    } else {
      return null;
    }
  }

  markCollection(collectionProp, name, type = undefined) {
    if (this.markDifferences) {
      let val = this.includes(this.tools[0][collectionProp], name, type);
      for (let i=0; i<this.tools.length; i++) {
        if (!this.tools[i].idx) continue;
        if (val !== this.includes(this.tools[i][collectionProp], name, type)) {
          return 'mark';
        }
      }
      return '';
    } else if (this.markSimilarities) {
      let val = this.includes(this.tools[0][collectionProp], name, type);
      for (let i=0; i<this.tools.length; i++) {
        if (!this.tools[i].idx) continue;
        if (val !== this.includes(this.tools[i][collectionProp], name, type)) {
          return '';
        }
      }
      return 'mark';
    } else {
      return null;
    }
  }
}
