import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'link'
})
export class LinkPipe implements PipeTransform {

  transform(value: string): string {
    if (value.startsWith('http')) {
      return value;
    } else {
      return 'https://' + value;
    }
  }

}
