<ng-template [ngIf]="!reportTool">
  <h1>IMPROVE <em>YOUR PEST MANAGEMENT</em></h1>
  <h2>Find the right decision support system for you!</h2>

  <div class="search-form">
    <div class="upper">
      <div class="link quick-search" [ngClass]="{'active':!advanced}" (click)="setAdvanced(false)">Quick search</div>
      <div class="link advanced-search" [ngClass]="{'active':advanced}" (click)="setAdvanced(true)">Advanced search</div>
      <div class="link add right" (click)="add()">Add new DSS</div>
    </div>
    <app-quick-search *ngIf="!advanced" [showBrowse]="result===undefined" [selectedCountries]="countriesSelected" [(selectedCrops)]="cropsSelected" [(selectedPests)]="pestsSelected" [(selectedLanguages)]="languagesSelected" (onBrowse)="browse=true; search(filter)" (onSearch)="browse=false; search(filter)"></app-quick-search>
    <app-advanced-search *ngIf="advanced" [(name)]="name" [(advancedSearchBy)]="advancedSearchBy" [input]="result==undefined" (onSearch)="advancedSearch($event)" (onClear)="setAdvanced(false)"></app-advanced-search>
  </div>

  <app-search-result *ngIf="result" [result]="result" [(reportTool)]="reportTool" (filterChange)="filterChange($event)"></app-search-result>

  <div class="about">
    <ul>
      <li class="knowledge">
        <h3>Knowledge-based</h3>
        <em>Search over 70 indexed decision support systems</em>
      </li>
      <li class="trust">
        <h3>Trustworthy</h3>
        <em>Data gathered and maintained by a public research institute</em>
      </li>
      <li class="friend">
        <h3>User-friendly</h3>
        <em>Navigate thorough data with an easy-to-use interface</em>
      </li>
      <li class="open">
        <h3>Open</h3>
        <em>Constantly improving data and adding new decision support systems</em>
      </li>
    </ul>

    <h3>About IPM Adviser</h3>
    <div class="content">
      <div class="pest"></div>
      <p>The "IPM Adviser" is a web-based tool that allows searching through a catalogue of systematically described decision support systems (DSS) using broad set of criteria. Its main puspose is to provide users with access to unbiased information on available DSS in the field of Integrated Pest Management in Europe, to enable their comparison and to help them select the most approprite DSS for their specific needs. In addition, it allows researchers and developers of DSS to identify gaps in the tools developed and to devlop new DSS in a more targeted way.</p>
    </div>

    <h3>Connected with IPM Decisions Platform</h3>
    <div class="content">
      <div class="decisions"></div>
      <div class="decisions-about">The IPM Decisions Platform is a one-stop shop for decision support in integrated pest management (IPM). It is a web-based framework that provides farmers, advisors, researchers and DSS developers with access to a wide range of decision support systems and weather data from across Europe through a single user interface. The platform allows you to:
        <ol type="i">
          <li>use DSS,</li>
          <li>compare the results of the available DSS,</li>
          <li>adapt the parameters within the decision support systems to use them in a wider range of countries and</li>
          <li>develop novel DSS by combining different DSS or parts of DSS (models and tools).</li>
        </ol>
      </div>
    </div>
  </div>
</ng-template>
<app-report [tool]="reportTool" *ngIf="reportTool" (done)="reportTool=undefined"></app-report>