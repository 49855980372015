import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `
    <div class="container" [ngClass]="{shown: show}">
      <div class="mmodal">
        <div class="mmodal-body" [ngStyle]="style">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="mmodal-background" (click)="close()"></div>
    </div>
  `,
styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() show;
  @Input() width;
  @Output() showChange = new EventEmitter<boolean>();

  public style:any = {}
  
  constructor() { }

  ngOnInit() {
    if (this.width) {
      this.style['width'] = this.width + 'px';
    }
  }

  close() {
    this.showChange.emit(false);
  }
}