<ng-template [ngIf]="!comparison">
  <div class="filter">
    <h1>FILTER <em *ngIf="filter_num>0">{{filter_num}} <div class="link" (click)="clearFilters()">x</div></em></h1>
    <ul>
      <li *ngFor="let root of result.filter">
        <h2 [ngClass]="root.class">{{root.title}}</h2>
        <ul>
          <li *ngFor="let section of root.sections">
            <h3 [ngClass]="section.class" [class.active]="section.active" (click)="toggleOpen(section)">{{section.title}}</h3>
            <div *ngIf="section.open">
              <div *ngFor="let subsection of section.subsections">
                <h4>{{subsection.title}}</h4>
                <div class="checkbox" *ngFor="let item of subsection.items">
                  <app-checkbox [name]="item.title" ngDefaultControl [(ngModel)]="item.checked" (onChange)="submitFilterChange(root, section, subsection, item); checkActive(section)" [label]="item.title + ' (' + item.num + ')'"></app-checkbox>
                  <div class="indented" *ngIf="item.items">
                    <app-checkbox *ngFor="let subitem of item.items" [name]="subitem.title" ngDefaultControl [(ngModel)]="subitem.checked" (onChange)="submitFilterChange(root, section, subsection, item, subitem); checkActive(section)" [label]="subitem.title + ' (' + subitem.num + ')'"></app-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="results">
    <ul class="sort">
      <li (click)="toggleDirection()" class="heading clickable" [ngClass]="{'asc': direction === 'asc', 'desc': direction === 'desc'}">SORT</li>
      <li (click)="setSort('name')" class="clickable" [ngClass]="{active: sort==='name'}">Name</li>
      <li (click)="setSort('country')" class="clickable" [ngClass]="{active: sort==='country'}">Country</li>
      <li (click)="setSort('price')" class="clickable" [ngClass]="{active: sort==='price'}">Price</li>
      <li (click)="setSort('date')" class="clickable" [ngClass]="{active: sort==='date'}">Date</li>
      <li class="total">{{sorted.length}}</li>
    </ul>
    <ul class="tools">
      <li class="tool" *ngFor="let tool of sorted">
        <app-tool [tool]="tool" [arableCrops]="arableCrops" [otherCrops]="otherCrops" [diseases]="diseases" [insects]="insects" (selectedChange)="toggleSelect(tool)" (reportClick)="report(tool)"></app-tool>
      </li>
    </ul>
  </div>
  <div class="comparison" *ngIf="forComparison.length>1">
    <span class="button clickable secondary" (click)="clearComparison()">Clear selection</span>
    <span *ngIf="forComparison.length<5" class="button clickable" (click)="viewComparison()">Go to comparison of {{forComparison.length}} selected DSS</span>
    <span *ngIf="forComparison.length>=5" class="button">Maximum for comparison is 4. Please unselect.</span>
  </div>
</ng-template>
<app-comparison [(tools)]="forComparison" [arableCrops]="arableCrops" [otherCrops]="otherCrops" [diseases]="diseases" [insects]="insects" *ngIf="comparison" (backClicked)="comparison=false"></app-comparison>