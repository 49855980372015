<div class="top">
  <div class="nonempty" *ngIf="!(selectedItems.length == 0)">
    <div class="items">
      <div class="item" *ngFor="let item of selectedItems"><span>{{item.value}}</span><div class="remove" (click)="removeItem(item)"></div></div>
    </div>
    <div class="open" (click)="openList()"></div>
  </div>
  <div class="search">
    <div class="list" [ngClass]="{empty: selectedItems.length==0}" *ngIf="open">
      <ul *ngIf="filtered.length > 0">
        <li><label class="all"><input type="checkbox" (click)="toggleAll($event)" [checked]="selectedItems.length === 0">Any</label></li>
        <li *ngFor="let item of filtered">
          <label><input type="checkbox" [checked]="selectedItems.includes(item)" (change)="toggle(item)">{{item.value}}</label>
        </li>
      </ul>
      <ul *ngIf="filtered.length === 0">
        <li><label>No results</label></li>
      </ul>
    </div>
    <div class="input">
      <input type="text" [placeholder]="(open) ? '' : placeholder" [ngClass]="{isOpen: open}" [(ngModel)]="search" *ngIf="open || selectedItems.length == 0"/>
      <div class="close" *ngIf="open" (click)="closeList()"></div>
    </div>
  </div>
</div>