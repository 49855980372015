<div class="report-container">
  <div class="close" (click)="close()"></div>
  <div *ngIf="!submitted">
    <h1>Update information for:</h1>
    <h2>{{tool.name}}</h2>
    <h3>Let us know how to improve the data</h3>
    <div class="center">
      <div class="input spaceunder" [ngClass]="{error: errorFields.includes('comment')}"><em>Comment <app-info content="Message to the editor."></app-info></em> <textarea [(ngModel)]="comment"></textarea></div>
    </div>

    <h3>Input your contact info</h3>

    <div class="center">
      <div class="input" [ngClass]="{error: errorFields.includes('firstname')}"><em>Name</em> <input type="text" [(ngModel)]="firstname" /></div>
      <div class="input" [ngClass]="{error: errorFields.includes('lastname')}"><em>Surname</em> <input type="text" [(ngModel)]="lastname" /></div>
      <div class="input" [ngClass]="{error: errorFields.includes('email')}"><em>Email</em> <input type="text" [(ngModel)]="email" /></div>
      <div class="input spaceunder" [ngClass]="{error: errorFields.includes('role')}"><em>Role <app-info content="What is your role in the organization?"></app-info></em>
        <app-radio-group [items]="roleItems" [(selected)]="role"></app-radio-group>
      </div>
      <div class="error spaceunder" *ngIf="error">{{error}}</div>
    </div>

    <div class="bottom" (click)="submit()">
      <app-loading [display]="loading"></app-loading>
      <span class="confirm" [ngClass]="{loading: loading}">Confirm</span>
    </div>
  </div>
  <div *ngIf="submitted">
    <h3 class="smiley">Thank you!</h3>
    <div class="summary">
      <span>The observed mistake has been successfully reported.</span>
      <span>We will get back to you as soon as possible.</span>
    </div>
    <div class="bottom" (click)="close()">
      <span class="return">Return to homepage</span>
    </div>
  </div>
</div>