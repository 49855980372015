import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { SearchResultComponent } from './search/search-result/search-result.component';
import { AnalyzeComponent } from './search/analyze/analyze.component';
import { SubmitComponent } from './submit/submit.component';
import { ReportComponent } from './search/report/report.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { DropdownComponent } from './search/dropdown/dropdown.component';
import { ToolComponent } from './search/search-result/tool/tool.component';
import { ModalComponent } from './modal/modal.component';
import { PestDiseaseListComponent } from './search/search-result/tool/pest-disease-list/pest-disease-list.component';
import { QuickSearchComponent } from './search/quick-search/quick-search.component';
import { AdvancedSearchComponent } from './search/advanced-search/advanced-search.component';
import { ItemListComponent } from './search/search-result/tool/item-list/item-list.component';
import { ProgressComponent } from './search/advanced-search/progress/progress.component';
import { NodeInputComponent } from './search/advanced-search/node-input/node-input.component';
import { CheckboxComponent } from './search/checkbox/checkbox.component';
import { ComparisonComponent } from './search/search-result/comparison/comparison.component';
import { WidthPipe } from './width.pipe';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { InfoComponent } from './info/info.component';
import { LoadingComponent } from './loading/loading.component';
import { LinkPipe } from './link.pipe';
import { NonemptyPipe } from './nonempty.pipe';
import { FormatDSSDescriptionPipe } from './format-dssdescription.pipe';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'ipmadviser.ijs.si' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom",
  theme: "edgeless",
  palette: {
    popup: {
      background: "#707070",
      text: "#ffffff"
    },
    button: {
      background: "#E4E4E4",
      text: "#707070"
    }
  },
  type: "opt-in",
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </span>
    `,
  },
  content:{
    message: 'Our website uses cookies to optimize your user experience. For more information please see our ',
    
    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: '/assets/IPMAdviser_Privacy_Policy.pdf',
 
    tosLink: 'Terms and Conditions',
    tosHref: '/assets/IPMAdviser_Terms_Conditions.pdf',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    SearchResultComponent,
    AnalyzeComponent,
    SubmitComponent,
    ReportComponent,
    FooterComponent,
    HeaderComponent,
    DropdownComponent,
    ToolComponent,
    ModalComponent,
    ItemListComponent,
    PestDiseaseListComponent,
    QuickSearchComponent,
    AdvancedSearchComponent,
    ProgressComponent,
    NodeInputComponent,
    CheckboxComponent,
    ComparisonComponent,
    WidthPipe,
    RadioGroupComponent,
    InfoComponent,
    LoadingComponent,
    LinkPipe,
    NonemptyPipe,
    FormatDSSDescriptionPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    GoogleTagManagerModule.forRoot({
      id: "GTM-MSJHVDB"
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
