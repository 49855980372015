<ul>
  <li *ngFor="let node of nodes; let idx = index" [class.first]="idx==0" [class.last]="idx==nodes.length-1">
    <div [ngClass]="{'dark': idx === current}" (click)="select(idx)">
      <span><em>{{idx+1}}</em></span>
      <h3>{{node.title}}</h3>
    </div>
    <svg [attr.width]="width" [attr.height]="height" class="bg-line">
      <line *ngIf="idx==0; else second" [attr.x1]="width/2" [attr.y1]="height/2" [attr.x2]="width" [attr.y2]="height/2" [attr.stroke-width]="strokeWidth" />
      <ng-template #second>
        <line *ngIf="idx==nodes.length-1; else default" x1="0" [attr.y1]="height/2" [attr.x2]="width/2" [attr.y2]="height/2" [attr.stroke-width]="strokeWidth" />
      </ng-template>
      <ng-template #default>
        <line x1="0" [attr.y1]="height/2" [attr.x2]="width" [attr.y2]="height/2" [attr.stroke-width]="strokeWidth" />
      </ng-template>
    </svg>
  </li>
</ul>