import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit {
  public submitted: boolean = false;
  public loading = false;

  public firstname = "";
  public lastname = "";
  public email = "";
  public role;

  public comment = "";
  public dssName = "";
  public link = "";
  public owner = "";
  public error;

  public roleItems;
  public errorFields = [];

  constructor(private api: ApiService, private router: Router, private gtm: GtmService) {
    this.roleItems = this.api.roleItems;
  }

  ngOnInit(): void {
    this.gtm.push({
      event: 'page',
      pageName: '/submit'
    });
    window.scroll({ 
      top: 0, 
      left: 0
    });
  }

  missingMandatoryFields() {
    let missing = false;

    let mandatoryFields = ['firstname', 'lastname', 'email', 'role', 'dssName', 'owner'];

    for (let field of mandatoryFields) {
      if (this[field] === undefined || this[field] === null || this[field] === "") {
        this.errorFields.push(field);
        missing = true;
      }
    }
    return missing;
  }

  submit() {
    this.error = undefined;
    this.errorFields = [];

    if (this.missingMandatoryFields()) {
      this.error = "Please complete all mandatory fields";
      return;
    }

    if (!this.api.isValidEmail(this.email)) {
      this.error = "Invalid Email address";
      this.errorFields.push('email');
      return;
    }
    

    this.gtm.push({
      event: 'submit-submit',
    });

    this.loading = true;
    this.api.submitTool(this.firstname, this.lastname, this.email, this.role2txt(this.role), this.comment, this.dssName, this.link, this.owner).subscribe(() => {
      this.submitted = true;
      this.loading = false;
    }, (e) => {
      this.submitted = false;
      this.loading = false;
      this.error = "An error occurred. Please try again later.";
    });
  }

  role2txt(roleId) {
    let role = this.roleItems.find(r => r.id===roleId);

    if (role) {
      return role.name;
    } else {
      return "";
    }
  }

  close() {
    this.router.navigate(["/"]);
  }
}
