<div>
  <div class="quick-container">
    <ul>
      <li class="country">
        <span>Country <app-info content='Country where DSS was developed. * Marks assumed values - based on country of  "Owner of DSS"'></app-info></span>
        <app-dropdown name="country" placeholder="e.g. Germany" [all]="countries" [(selectedItems)]="selectedCountries"></app-dropdown>
      </li>
      <li class="crop">
        <span>Crop <app-info content="Crops for which you need a decision support system."></app-info></span>
        <app-dropdown name="crop" placeholder="e.g. Cereals" [all]="crops" [(selectedItems)]="selectedCrops"></app-dropdown>
      </li>
      <li class="pest">
        <span>Pest <app-info content="Insects, diseases or weeds for which you need a decision support system."></app-info></span>
        <app-dropdown name="pest" placeholder="e.g. Aphids" [all]="pests" [(selectedItems)]="selectedPests"></app-dropdown>
      </li>
      <li class="language">
        <span>Language <app-info content="Language of the IPM DSS interface."></app-info></span>
        <app-dropdown name="language" placeholder="e.g. English" [all]="languages" [(selectedItems)]="selectedLanguages"></app-dropdown>
      </li>
      <li class="search">
        <span (click)="search()">Search</span>
      </li>
    </ul>
    <div class="browse">
      <div *ngIf="showBrowse" (click)="browse()" class="link browse right">Browse the catalogue</div>
    </div>
  </div>
</div>