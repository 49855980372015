import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pest-disease-list',
  templateUrl: './pest-disease-list.component.html',
  styleUrls: ['./pest-disease-list.component.scss']
})
export class PestDiseaseListComponent implements OnInit {
  private _values = [];

  private types = [
    null, "Diseases", "Insects", "Weeds"
  ];

  @Input() public set pest_diseases(values) {
    let list:any = {};
    for (let item of values) {
      if (!list[item.type_id]) {
        list[item.type_id] = {
          type_id: item.type_id,
          name: this.types[item.type_id],
          items: []
        }
      }
      list[item.type_id].items.push(item);
    }

    this._values = Object.values(list);
  }

  public get pest_diseases() {
    return this._values;
  }
  

  constructor() { }

  ngOnInit(): void {
  }

}
