<div class="table">
  <div class="row first main">
    <div class="col header">
      <div class="back" (click)="back()"></div>
      <span class="link" (click)="toggleMarkDifferences()" [ngClass]="{active: markDifferences}">DIFFERENCES</span>
      <span class="link" (click)="toggleMarkSimilarities()" [ngClass]="{active: markSimilarities}">SIMILARITIES</span>
      <h1>DSS</h1>
    </div>
    <div class="col" *ngFor="let tool of alltools">
      <div *ngIf="tool.idx" class="remove" (click)="remove(tool)"></div>
      <div *ngIf="!tool.idx" class="button" (click)="back()">Add DSS to comparison</div>
      <div class="logo"><img *ngIf="tool.logo" [src]="'/api/images/logo/' + tool.logo" /></div>
      <em>{{tool.name | width:200:'23px "Segoe UI SemiBold"'}}</em>
    </div>
  </div>
  <div class="row section">
    <div class="col header"><h2 class="basic">Basic information</h2></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Tool</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('idx')">
    <div class="col header">Consecutive number</div>
    <div class="col" *ngFor="let tool of alltools">{{tool.idx | nonempty}}</div>
  </div>
  <div class="row" [ngClass]="mark('name')">
    <div class="col header">Name of DSS</div>
    <div class="col" *ngFor="let tool of alltools">{{tool.name | width:224:'14px "Segoe UI Regular"'}}</div>
  </div>
  <div class="row" [ngClass]="mark('links|0|url')">
    <div class="col header">Link to web page</div>
    <div class="col" *ngFor="let tool of alltools"><span>{{(tool.links && tool.links.length>0 ? tool.links[0].url : '') | width:224:'14px "Segoe UI Regular"'}}</span></div>
  </div>
  <div class="row" [ngClass]="mark('owner')">
    <div class="col header">Owner of DSS</div>
    <div class="col" *ngFor="let tool of alltools">{{tool.owner | width:224:'14px "Segoe UI Regular"'}}</div>
  </div>
  <div class="row" [ngClass]="mark('development_country|name')">
    <div class="col header">Country of development</div>
    <div class="col" *ngFor="let tool of alltools">{{((tool.development_country ? tool.development_country.name : '') + (tool.development_country_assumed ? '*' : '')) | width:224:'14px "Segoe UI Regular"'}}</div>
  </div>

  <div class="row" [ngClass]="mark('tested_countries')">
    <div class="col header">Country - tested</div>
    <div class="col" *ngFor="let tool of alltools">{{tool.tested_countries | width:224:'14px "Segoe UI Regular"'}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Geographic focus</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('geographic_focus_neu')">
    <div class="col header">Northern Europe</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.geographic_focus_neu)}}</div>
  </div>
  <div class="row" [ngClass]="mark('geographic_focus_ceu')">
    <div class="col header">Central Europe</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.geographic_focus_ceu)}}</div>
  </div>
  <div class="row" [ngClass]="mark('geographic_focus_seu')">
    <div class="col header">Southern Europe</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.geographic_focus_seu)}}</div>
  </div>
  
  <div class="row section">
    <div class="col header"><h2 class="challenge">Challenge</h2></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Definition</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('definition')">
    <div class="col header">Water pollution / Plant protection</div>
    <div class="col" *ngFor="let tool of alltools">{{plantOrWater(tool.definition)}}</div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Constraints</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('space_constraints')">
    <div class="col header">Space</div>
    <div class="col" *ngFor="let tool of alltools">{{nationalInternational(tool.space_constraints)}}</div>
  </div>
  <div class="row" [ngClass]="mark('time_constraints')">
    <div class="col header">Time</div>
    <div class="col" *ngFor="let tool of alltools">{{shortLong(tool.time_constraints)}}</div>
  </div>
  
  <div class="row section">
    <div class="col header"><h2 class="problem">Problem</h2></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Plant protection</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Arable crops</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" *ngFor="let crop of arableCrops" [ngClass]="markCollection('crops', crop)">
    <div class="col header">{{crop}}</div>
    <div class="col" *ngFor="let tool of alltools">{{(tool.crops ? includes(tool.crops, crop) : '') | nonempty}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3></h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" *ngFor="let crop of otherCrops" [ngClass]="markCollection('crops', crop)">
    <div class="col header">{{crop}}</div>
    <div class="col" *ngFor="let tool of alltools">{{(tool.crops ? includes(tool.crops, crop): '') | nonempty}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Diseases</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" *ngFor="let disease of diseases" [ngClass]="markCollection('pest_diseases', disease, 1)">
    <div class="col header">{{disease}}</div>
    <div class="col" *ngFor="let tool of alltools">{{(tool.pest_diseases ? includes(tool.pest_diseases, disease, 1): '') | nonempty}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Insects</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" *ngFor="let insect of insects" [ngClass]="markCollection('pest_diseases', insect, 2)">
    <div class="col header">{{insect}}</div>
    <div class="col" *ngFor="let tool of alltools">{{(tool.pest_diseases ? includes(tool.pest_diseases, insect, 2) : '') | nonempty}}</div>
  </div>
  <div class="row" [ngClass]="markCollection('pest_diseases', 'Weeds')">
    <div class="col header">Weeds</div>
    <div class="col" *ngFor="let tool of alltools">{{(tool.pest_diseases ? includes(tool.pest_diseases, "Weeds") : '') | nonempty}}</div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Function</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('function_monitoring')">
    <div class="col header">Monitoring</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.function_monitoring)}}</div>
  </div>
  <div class="row" [ngClass]="mark('function_forecasting')">
    <div class="col header">Forecasting</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.function_forecasting)}}</div>
  </div>
  <div class="row" [ngClass]="mark('function_planning')">
    <div class="col header">Planning</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.function_planning)}}</div>
  </div>

  <div class="row section">
    <div class="col header"><h2 class="analysis">Analysis</h2></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Implemenation</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Language</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('languages|0|name')">
    <div class="col header">Available languages</div>
    <div class="col" *ngFor="let tool of alltools"><ng-template [ngIf]="tool.languages"><span *ngFor="let lang of tool.languages; let idx=index">{{idx > 0 ? ', ' : ''}}{{lang.name}}</span></ng-template><ng-template [ngIf]="!tool.languages">&nbsp;</ng-template></div>
  </div>
  <div class="row" [ngClass]="mark('national_language')">
    <div class="col header">National</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.national_language)}}</div>
  </div>
  <div class="row" [ngClass]="mark('available_in_english')">
    <div class="col header">Available in English</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.available_in_english)}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3></h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('login_required')">
    <div class="col header">Login required</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.login_required)}}</div>
  </div>
  <div class="row" [ngClass]="mark('access_to_references')">
    <div class="col header">Access to references</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.access_to_references)}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Price</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('price')">
    <div class="col header">Free</div>
    <div class="col" *ngFor="let tool of alltools">{{freeNonfree(tool.price)}}</div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Data required</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Weather</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('weather')">
    <div class="col header">Weather</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.weather)}}</div>
  </div>
  <div class="row" [ngClass]="mark('weather_data')">
    <div class="col header">Obtaining weather data</div>
    <div class="col" *ngFor="let tool of alltools">{{autoManual(tool.weather_data)}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3>Field data</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('field_observations')">
    <div class="col header">Field observations</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.field_observations)}}</div>
  </div>
  <div class="row" [ngClass]="mark('field_sowing_date')">
    <div class="col header">Date of sowing</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.field_sowing_date)}}</div>
  </div>
  <div class="row" [ngClass]="mark('field_pre_crop')">
    <div class="col header">Pre crop</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.field_pre_crop)}}</div>
  </div>
  <div class="row" [ngClass]="mark('field_crop_variety')">
    <div class="col header">Crop variety</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.field_crop_variety)}}</div>
  </div>
  <div class="row" [ngClass]="mark('field_other')">
    <div class="col header">Other data</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.field_other)}}</div>
  </div>
  <div class="row section">
    <div class="col header"><h2 class="outcomes">Outcomes</h2></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Output</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('output_map')">
    <div class="col header">Map</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.output_map)}}</div>
  </div>
  <div class="row" [ngClass]="mark('output_text')">
    <div class="col header">Text</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.output_text)}}</div>
  </div>
  <div class="row" [ngClass]="mark('output_numerical')">
    <div class="col header">Numerical/categorical</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.output_numerical)}}</div>
  </div>
  <div class="row subsection">
    <div class="col header"><h3>Target</h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('audience_farmers')">
    <div class="col header">Farmers</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.audience_farmers)}}</div>
  </div>
  <div class="row" [ngClass]="mark('audience_advisors')">
    <div class="col header">Advisors</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.audience_advisors)}}</div>
  </div>
  <div class="row" [ngClass]="mark('audience_researchers')">
    <div class="col header">Researchers</div>
    <div class="col" *ngFor="let tool of alltools">{{yesOrNo(tool.audience_researchers)}}</div>
  </div>
  <div class="row subitems">
    <div class="col header"><h3></h3></div>
    <div class="col" *ngFor="let tool of alltools"></div>
  </div>
  <div class="row" [ngClass]="mark('scale')">
    <div class="col header">Scale</div>
    <div class="col" *ngFor="let tool of alltools">{{smallMediumLarge(tool.scale)}}</div>
  </div>
</div>