import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { SubmitComponent } from './submit/submit.component';


const routes: Routes = [
  {path: '', component: SearchComponent},
  {path: 'advanced', component: SearchComponent},
  {path: 'browse', component: SearchComponent},
  {path: 'submit', component: SubmitComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
