import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.scss']
})
export class ToolComponent implements OnInit {
  @Input() tool;
  @Output() selectedChange = new EventEmitter();
  @Output() reportClick = new EventEmitter();
  @Input() arableCrops;
  @Input() otherCrops;
  @Input() diseases;
  @Input() insects;

  public moreOpen = false;
  public urlmodalShow = false;
  public ipmmodalShow = false;

  constructor() { }

  ngOnInit(): void {
  }

  openModal() {
    this.urlmodalShow = true;
  }

  onModalClose(e) {
    let self = this;
    setTimeout(() => {
      self.urlmodalShow = e;
    });
  }

  onIPMModalClose(e) {
    let self = this;
    setTimeout(() => {
      self.ipmmodalShow = e;
    });
  }

  openLink() {
    window.open("https://www.platform.ipmdecisions.net/");
  }

  price2string(price) {
    switch(price) {
      case 'F':
        return 'Free';
      case 'L':
        return 'Limited';
      case 'N':
        return 'Not free';
      default:
        return '';
    }
  }

  toggleMoreOpen() {
    this.moreOpen = !this.moreOpen;
  }

  yesOrNo(val) {
    return (val ? 'Yes' : 'No');
  }

  autoManual(val) {
    if (val === 'A') {
      return 'Automatic';
    } else if (val === 'M') {
      return 'Manual';
    } else if (val === 'N') {
      return 'No';
    }

    return '';
  }

  freeNonfree(val) {
    if (val === 'F') {
      return 'Free';
    } else if (val == 'N') {
      return 'Not free';
    } else if (val == 'L') {
      return 'Limited';
    }

    return '';
  }

  onlineOffline(val) {
    if (val === 'ONL') {
      return 'Online';
    } else if (val === 'OFL') {
      return 'Offline';
    }
    return '';
  }

  nationalInternational(val) {
    if (val === 'N') {
      return 'National';
    } else if (val === 'I') {
      return 'International';
    }
    return '';
  }

  shortLong(val) {
    if (val === 'S') {
      return 'Short-term';
    } else if (val ==='L') {
      return 'Long-term'
    } else if (val === 'B') {
      return 'Both;'
    }

    return '';
  }

  plantOrWater(val) {
    if (val === 'PP') {
      return 'Plant protection';
    } else if (val === 'WP') {
      return 'Water pollution';
    }
    return '';
  }

  smallMediumLarge(val) {
    if (val === 'S') {
      return 'Small';
    } else if (val === 'M') {
      return 'Medium';
    } else if (val === 'L') {
      return 'Large';
    }
    return '';
  }

  toggleComparison() {
    this.selectedChange.emit();
  }

  includes(collection, name, type = undefined) {
    let potential;
    if (type) {
      potential = collection.filter(i => i.name === name && i.type_id === type);
    } else {
      potential = collection.filter(i => i.name === name);
    }

    return potential.length === 0 ? 'No' : 'Yes';
  }

  report() {
    this.reportClick.emit();
  }
}
