import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Input() label;
  @Input() info;

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.ngModelChange.emit(!this.ngModel);
    this.onChange.emit();
  }

  isString(item) {
    return (typeof item === 'string' || item instanceof String);
  }

  isArray(item) {
    return Array.isArray(item);
  }
}
