import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public roleItems = [
    {"id": 1, "name": "Owner / Developer"},
    {"id": 2, "name": "User: Farmer"},
    {"id": 3, "name": "User: Adviser"},
    {"id": 4, "name": "User: Researcher"},
    {"id": 5, "name": "Other"}
  ];

  constructor(private http: HttpClient) { }

  isValidEmail(email) {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat) !== null;
  }

  getCountries() {
    return this.http.get("/api/countries");
  }

  getCrops() {
    return this.http.get("/api/crops");
  }

  getDiseases() {
    return this.http.get("/api/pest-diseases");
  }

  getPests() {
    return this.http.get("/api/pests");
  }

  getLanguages() {
    return this.http.get("/api/languages");
  }

  getTools(name, countries, crops, pests, languages, advancedFilter, filter) {
    return this.http.post("/api/tools", {
      name,
      countries,
      crops,
      pests,
      languages,
      advancedFilter,
      filter
    }).pipe(
      map((res:any) => {
        res.results = res.results.map(t => {
          t.geographic_focus = [];
          if (t.geographic_focus_neu) {
            t.geographic_focus.push("Northern Europe");
          }
          if (t.geographic_focus_ceu) {
            t.geographic_focus.push("Central Europe");
          }
          if (t.geographic_focus_seu) {
            t.geographic_focus.push("Southern Europe");
          }
          return t;
        });

        res.filter = res.filter.map(f => {
          f.class = this.toCamelCase(f.title);
          for (let section of f.sections) {
            section.class = this.toCamelCase(section.title);
            for (let subsection of section.subsections) {
              subsection.class = this.toCamelCase(subsection.title);
            }
          }
          return f;
        });
        
        return res;
      })
    );
  }

  toCamelCase(inStr) {
    let words = inStr.split(" ");
    let ret = words.shift().toLowerCase();

    for (let word of words) {
      ret += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    return ret;
  }

  getFilter() {
    return this.http.get("/api/tools/filter").pipe(
      map((filter:any) => filter.map(f => {
        f.class = this.toCamelCase(f.title);
        for (let section of f.sections) {
          section.class = this.toCamelCase(section.title);
          for (let subsection of section.subsections) {
            subsection.class = this.toCamelCase(subsection.title);
          }
        }
        return f;
      }))
    );
  }

  submitTool(firstname, lastname, email, role, comment, dssName, link, owner) {
    let tool = {firstname, lastname, email, role, comment, dssName, link, owner}
    return this.http.post("/api/tools/new", tool);
  }

  submitReport(id, firstname, lastname, email, role, comment) {
    let report = {firstname, lastname, email, role, comment}
    return this.http.put("/api/tools/" + id, report);
  }

  getSync() {
    return this.http.get("/api/sync/latest");
  }
}
