import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'width'
})
export class WidthPipe implements PipeTransform {

  transform(value: string, length: number, font: string): string {

    if (value === undefined || value === null || value === "") {
      return " ";
    }

    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    context.font = font;

    let metrics = context.measureText(value);
    
    if (metrics.width <= length) {
      return value;
    } else {
      const suffix = "...";
      let diff = (metrics.width - length);
      let strip = Math.round(value.length * diff / metrics.width);

      let stripped = value.substring(0, value.length - strip - 1);

      while (stripped.length > 0) {
        let metrics = context.measureText(stripped + suffix);
        if (metrics.width <= length) {
          return stripped + suffix;
        }
        stripped = stripped.substring(0, stripped.length - 2);
      }

      return stripped;
    }
  }
}
