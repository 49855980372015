import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GtmService }  from '../../gtm.service';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  @Input() advancedSearchBy;
  @Output() advancedSearchByChange = new EventEmitter();

  @Input() name;
  @Output() nameChange = new EventEmitter();

  @Input() input;

  @Output() onSearch = new EventEmitter();
  @Output() onClear = new EventEmitter();
  
  public items;
  public currentIdx = 0;
  private filter;

  constructor(private api: ApiService, private gtm: GtmService) { }
  
  ngOnInit(): void {
    let items = [];

    this.api.getFilter().subscribe((filter) => {
      this.filter = filter;
      for (let root of filter) {
        if (root.title === 'Problem' || root.title === 'Analysis' || root.title === 'Outcomes') {
          for (let section of root.sections) {
            for (let subsection of section.subsections) {
              for (let item of subsection.items) {
                item.checked = false;
                if (!item.num) {
                  item.num = 0;
                }
                if (item.items) {
                  for (let subitem of item.items) {
                    subitem.checked = false;
                    if (!subitem.num) {
                      subitem.num = 0;
                    }
                  }
                }
              }
            }
          }
          items.push(root);
        }
      }
      this.items = items;
    });
  }

  setAdvancedSearchBy(value) {
    this.advancedSearchBy = value;
    this.advancedSearchByChange.emit(value);
  }

  onNameChange() {
    this.nameChange.emit(this.name);
  }

  prev(e) {
    e.preventDefault();
    this.currentIdx--;
  }

  next(e) {
    e.preventDefault();

    if (this.currentIdx < 2) {
      this.currentIdx++;
    } else {
      this.onSearch.emit(this.filter);
    }
  }

  update() {
    setTimeout(() => {      
      this.api.getTools(null, null, null, null, null, this.filter, null).subscribe((result) => {
        let items = [];
        
        for (let rootKey of Object.keys(this.filter)) {
          for (let sectionKey of Object.keys(this.filter[rootKey].sections)) {
            for (let subsectionKey of Object.keys(this.filter[rootKey].sections[sectionKey].subsections)) {
              for (let itemKey of Object.keys(this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items)) {
                if (result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey] && result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num) {
                  this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num = result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num;
                } else {
                  this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num = 0;
                }
                if (this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items) {
                  for (let subitemKey of Object.keys(this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items)) {
                    if (result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items && result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey] && result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num) {
                      this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num = 
                      result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num;
                    } else {
                      this.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num = 0;
                    }
                  }
                }
              }
            }
          }
        }
        for (let root of this.filter) {
          if (root.title === 'Problem' || root.title === 'Analysis' || root.title === 'Outcomes') {
            for (let section of root.sections) {
              for (let subsection of section.subsections) {
                for (let item of subsection.items) {
                  if (!item.num) {
                    item.num = 0;
                  }
                  if (item.items) {
                    for (let subitem of item.items) {
                      if (!subitem.num) {
                        subitem.num = 0;
                      }
                    }
                  }
                }
              }
            }
            items.push(root);
          }
        }
        this.items = items;
      });
    });
  }

  search() {
    this.gtm.push({
      event: 'advanced-search-by-name-or-id',
      data: this.name
    });
    this.onSearch.emit();
  }

  clearFilters(section) {
    for (let root of this.items) {
      if (root.title !== section) {
        continue;
      }
      for (let section of root.sections) {
        if (section.checked) {
          section.checked = false;
        }
        for (let subsection of section.subsections) {
          if (subsection.checked) {
            subsection.checked = false;
          }
          for (let item of subsection.items) {
            if (item.checked) {
              item.checked = false;
            }
            if (item.items) {
              for (let subitem of item.items) {
                if (subitem.checked) {
                  subitem.checked = false;
                }
              }
            }
          }
        }
      }
    }
  }

  filterNum(section) {
    let num = 0;
    
    for (let root of this.items) {
      if (root.title !== section) {
        continue;
      }
      for (let section of root.sections) {
        if (section.checked) {
          num++;
        }
        for (let subsection of section.subsections) {
          if (subsection.checked) {
            num++;
          }
          for (let item of subsection.items) {
            if (item.checked) {
              num++;
            }
            if (item.items) {
              for (let subitem of item.items) {
                if (subitem.checked) {
                  num++;
                }
              }
            }
          }
        }
      }
    }
    return num;
  }

  listFilters(section) {
    let items = [];
    for (let root of this.items) {
      if (root.title !== section) {
        continue;
      }
      for (let section of root.sections) {
        if (section.checked) {
          items.push(section);
        }
        for (let subsection of section.subsections) {
          if (subsection.checked) {
            items.push(subsection);
          }
          for (let item of subsection.items) {
            if (item.checked) {
              items.push(item);
            }
            if (item.items) {
              for (let subitem of item.items) {
                if (subitem.checked) {
                  items.push(subitem);
                }
              }
            }
          }
        }
      }
      return items;
    }
  }

  removeItem(section, forRemoval) {
    for (let root of this.items) {
      if (root.title !== section) {
        continue;
      }
      for (let section of root.sections) {
        if (section.checked && section.title == forRemoval.title) {
          section.checked = false;
        }
        for (let subsection of section.subsections) {
          if (subsection.checked && subsection.title == forRemoval.title) {
            subsection.checked = false;
          }
          for (let item of subsection.items) {
            if (item.checked && item.title == forRemoval.title) {
              item.checked = false;
            }
            if (item.items) {
              for (let subitem of item.items) {
                if (subitem.checked && subitem.title == forRemoval.title) {
                  subitem.checked = false;
                }
              }
            }
          }
        }
      }
    }
  }

  clear() {
    this.onClear.emit();
    this.currentIdx = 0;
    this.items = [];
  }
}
