import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatDSSDescription'
})
export class FormatDSSDescriptionPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    let formatted = value;

    if (value) {
      let res = value.match(/[A-Z][A-Z. ]*:/g);
      if (res && Array.isArray(res)) {
        for (let item of res) {
          if (formatted.startsWith(item)) {
            formatted = formatted.replace(item, "<b>" + item + "</b>")
          } else {
            formatted = formatted.replace(item, "<br/><br/><b>" + item + "</b>")
          }          
        }
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(formatted);
  
  }
}
