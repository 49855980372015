import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit {
  @Input() showBrowse;
  @Input() selectedCountries;
  @Input() selectedCrops;
  @Input() selectedPests;
  @Input() selectedLanguages;
  @Output() onSearch = new EventEmitter();
  @Output() onBrowse = new EventEmitter();

  public countries;
  public crops;
  public pests;
  public languages;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.getCountries().subscribe((countries:any) => {
      this.countries = countries.map(c=>({"id":c.country_id, "value":c.name}));
    });
    this.api.getCrops().subscribe((crops:any) => {
      this.crops = crops.map(c=>({"id":c.pest_disease_id, "value":c.name}));
    });
    this.api.getDiseases().subscribe((pests:any) => {
      this.pests = pests.map(c=>({"id":c.pest_disease_id, "value":c.name}));
    });
    this.api.getLanguages().subscribe((languages:any) => {
      this.languages = languages.map(c=>({"id":c.language_id, "value":c.name}));
    });
  }

  search() {
    this.onSearch.emit();
  }

  browse() {
    this.onBrowse.emit();
  }
}
