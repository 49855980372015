import { Component, Input, Output, EventEmitter, OnInit, HostBinding, HostListener } from '@angular/core';
import { GtmService } from 'src/app/gtm.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() public set all(val) {
    this._all = val;
    this.filtered = this._all;
  }
  public get all() {
    return this._all;
  }
  @Input() selectedItems;
  @Input() placeholder;
  @Output() selectedItemsChange = new EventEmitter();

  @Input() name;

  @HostBinding('tabindex') tabindex = 0;

  private _all;
  public filtered = [];

  private _updated: number;
  private _search: string = "";
  public get search() {
    return this._search;
  }
  public set search(val) {
    this._search = val;
    let updated = new Date().getTime();
    this._updated = updated;

    setTimeout(() => this.checkSettled(val, updated), 1000);

    if (this._search === "") {
      this.filtered = this._all;
    } else {
      this.filtered = this._all.filter(a => a.value.toLowerCase().indexOf(this._search) === 0);
    }
  }

  public open: boolean = false;
  public unfocusing: boolean = false;

  constructor(private gtm: GtmService) { }

  @HostListener('focusin')
  focusInHandler() {
    this.unfocusing = false;
    if (this.selectedItems.length == 0) {
      this.open = true;
    }
  }

  @HostListener('focusout')
  focusOutHandler() {
    this.unfocusing = true;
    setTimeout(this.safeUnfocus.bind(this), 100);
  }

  ngOnInit(): void {
  }

  openList() {
    this.open = true;
  }

  closeList() {
    this.open = false;
    this._search = "";
  }

  removeItem(item) {
    if (this.selectedItems.includes(item)) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }

  safeUnfocus() {
    if (this.unfocusing == true) {
      this.closeList();
    }
  }

  toggle(item) {
    if (this.selectedItems.includes(item)) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    } else {
      this.selectedItems.push(item);
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }

  toggleAll(e) {
    if (this.selectedItems.length === 0) {
      // don't do anything
      e.preventDefault();
    } else {
      this.selectedItems.splice(0, this.selectedItems.length);
      this.selectedItemsChange.emit(this.selectedItems);
    }
  }

  public checkSettled(val, updated) {
    if (this._search === val && this._updated === updated) {
      this.gtm.push({
        event: 'quick_search_dropdown',
        item_category: this.name,
        data: this._search
      });
    }
  }
}
