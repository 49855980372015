import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { GtmService } from 'src/app/gtm.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  @Input() public set result(value) {
    if (this._result && this._result.filter) {
      
      this._result.results = value.results;

      for (let rootKey of Object.keys(this._result.filter)) {
        for (let sectionKey of Object.keys(this._result.filter[rootKey].sections)) {
          for (let subsectionKey of Object.keys(this._result.filter[rootKey].sections[sectionKey].subsections)) {
            for (let itemKey of Object.keys(this._result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items)) {
              if (value.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey] && value.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num) {
                this._result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num = value.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num;
              } else {
                this._result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].num = 0;
              }
              if (this._result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items) {
                for (let subitemKey of Object.keys(this._result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items)) {
                  if (value.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items &&
                    value.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey] && 
                    value.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num) {
                      this._result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num = value.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num;
                    } else {
                      this._result.filter[rootKey].sections[sectionKey].subsections[subsectionKey].items[itemKey].items[subitemKey].num = 0;
                    }
                }
              }
            }
          }
        }
      }
    } else {
      for (let root of value.filter) {
        for (let section of root.sections) {
          for (let subsection of section.subsections) {
            for (let item of subsection.items) {
              if (item.checked) {
                section.active = true;
              } else {
                item.checked = false;
              }
              if (!item.num) {
                item.num = 0;
              }
              if (item.items) {
                for (let subitem of item.items) {
                  if (subitem.checked) {
                    section.active = true;
                  } else {
                    subitem.checked = false;
                  }
                  if (!subitem.num) {
                    subitem.num = 0;
                  }
                }
              }
            }
          }
        }
      }
      this._result = value;
    }
    
    this.performSort();
  }
  public get result() {
    return this._result;
  }
  private _result;

  @Output() filterChange = new EventEmitter();
  
  @Input() reportTool;
  @Output() reportToolChange = new EventEmitter();

  public sorted;
  public sort = "name";
  public direction = "asc";

  public filter_num = 0;
  public forComparison = [];
  public comparison = false;

  public arableCrops;
  public otherCrops;
  public diseases;
  public insects;

  constructor(private api: ApiService, private gtm: GtmService) { }

  ngOnInit(): void {
    this.api.getCrops().subscribe((crops:any) => {
      this.arableCrops = crops.filter(c => c.arable).map(c => c.name);
      this.otherCrops = crops.filter(c => !c.arable).map(c => c.name);
    });
    this.api.getDiseases().subscribe((pests:any) => {
      this.diseases = pests.filter(p => p.type_id == 1).map(p => p.name);
      this.insects = pests.filter(p => p.type_id == 2).map(p => p.name);
    });
  }

  setSort(sort) {
    if (this.sort === sort) {
      this.toggleDirection();
    } else {
      this.sort = sort;
      this.performSort();
    }
  }

  toggleDirection() {
    if (this.direction === 'asc') {
      this.direction = 'desc';
    } else {
      this.direction = 'asc';
    }
    this.performSort();
  }

  performSort() {
    let comp;
    if (this.sort === "name") {
      comp = (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
    } else if (this.sort === "country") {
      comp = (a, b) => (a.assumed_development_country.name > b.assumed_development_country.name) ? 1 : ((b.assumed_development_country.name > a.assumed_development_country.name) ? -1 : 0);
    } else if (this.sort === "date") {
      comp = (a, b) => (new Date(a.added) > new Date(b.added)) ? 1 : ((new Date(b.added) > new Date(a.added)) ? -1 : 0);
    } else if (this.sort === "price") {
      // F = free, L = limited free version, N = nonfree
      comp = (a, b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0);
    }

    let intermediate = this._result.results.sort(comp);

    if (this.direction === "desc") {
      this.sorted = intermediate.reverse();
    } else {
      this.sorted = intermediate;
    }
  }

  toggleOpen(section) {
    section.open = !section.open;
  }

  checkActive(section) {
    let active = false;

    for (let subsection of section.subsections) {
      for (let item of subsection.items) {
        if (item.checked) {
          active = true;
        }

        if (item.items) {
          for (let subitem of item.items) {
            if (subitem.checked) {
              active = true;
            }
          }
        }
      }
    }
    section.active = active;
    this.updateFilterNum();
    this.reload();
  }

  submitFilterChange(root, section, subsection, item, subitem = null) {
    let value;

    let path = root.title + "/" + section.title;

    if (subsection && subsection.title) {
      path += "/" + subsection.title;
    }

    if (item) {
      path += "/" + item.title;
      if (subitem) {
        path += "/" + subitem.title;
        value = subitem.checked;
      } else {
        value = item.checked;
      }
    }
    
    this.gtm.push({
      event: 'filter-click',
      item_category: path,
      data: value ? "on": "off"
    });
  }

  updateFilterNum() {
    let num = 0;
    for (let root of this._result.filter) {
      for (let section of root.sections) {
        if (section.active) {
          num++;
        }
      }
    }
    this.filter_num = num;
  }

  reload() {
    this.filterChange.emit(this.result.filter);
  }

  clearFilters() {
    for (let root of this.result.filter) {
      for (let section of root.sections) {
        section.active = false;
        section.open = false;
        for (let subsection of section.subsections) {
          for (let item of subsection.items) {
            item.checked = false;
            
            if (item.items) {
              for (let subitem of item.items) {
                subitem.checked = false;
              }
            }
          }
        }
      }
    }
    this.updateFilterNum();
    this.reload();
  }

  toggleSelect(tool) {
    tool.selected = !tool.selected;
    if (tool.selected) {
      this.forComparison.push(tool);
    } else {
      this.forComparison = this.forComparison.filter(t => t.tool_id !== tool.tool_id);
    }
  }

  viewComparison() {
    this.comparison = true;
    window.scroll(0,400);
  }

  clearComparison() {
    for (let tool of this.forComparison) {
      tool.selected = false;
    }
    this.forComparison = [];
  }

  report(tool) {
    this.reportToolChange.emit(tool);
  }
}
