<div class="container">
  <div class="section" *ngFor="let section of items.sections">
    <h2 [ngClass]="section.class">{{section.title}} <app-info *ngIf="section.info" [content]="section.info"></app-info></h2>
    <div class="subsections" [ngClass]="{'cols': cols}">
      <div class="subsection" *ngFor="let subsection of section.subsections">
        <h3 *ngIf="subsection.title!==''">{{subsection.title}} <app-info *ngIf="subsection.info" [content]="subsection.info"></app-info></h3>
        <div class="items">
          <div class="item" *ngFor="let item of subsection.items">
            <app-checkbox [name]="item.title" [info]="item.info" ngDefaultControl (ngModelChange)="reportChange()" [(ngModel)]="item.checked" [label]="item.title + ' (' + item.num + ')'"></app-checkbox>
            <div class="item indented" *ngFor="let subitem of item.items">
              <app-checkbox [name]="subitem.title" [info]="subitem.info" ngDefaultControl (ngModelChange)="reportChange()" [(ngModel)]="subitem.checked" [label]="[{text:subitem.title, className: ''}, {text: subitem.latin_name ? ' (' + subitem.latin_name + ')' : '', className: 'italic'}, {text: ' (' + subitem.num + ')', className: ''}]"></app-checkbox>
            </div>
          </div>
      </div>
      </div>
    </div>
  </div>
</div>